/* @media only screen and (max-width: 600px) {
  .content {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
div,
th,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Kanit", sans-serif !important;
  font-weight: 300;
}
option,
select,
textarea,
input,
td,
label {
  font-family: "Kanit", sans-serif !important;
  font-weight: 300 !important;
}

th {
  text-align: center !important;
}
/* .content {
  padding: 70px 10px;
  padding-left: 260px;
  font-weight: 300 !important;
} */
.pageTitle {
  color: #333;
}
.tdCenter {
  text-align: center !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.td {
  padding: 5px 5px !important;
}
.underLineTd {
  padding: 5px 5px !important;
  border-bottom: 1px solid #111 !important;
}
.smallTd {
  padding: 5px 5px !important;
  font-size: 0.7rem !important;
}
.bold {
  font-weight: 400 !important;
}
.bold-500 {
  font-weight: 500 !important;
}

.test {
  width: 400px;
  height: 400px;
  max-height: 50vw;
  max-width: 50vw;
  margin: auto;
  border: 2px solid #fff;
  background-size: cover;
  background-position: center center;
}

.selected-ingredient {
  border: 5px solid #fff !important;
  width: 220px !important;
  height: 220px !important;
}
